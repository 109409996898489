<template>
  <div class="info-wrap">
    <div class="title">资料设置</div>
    <div class="form-wrap" v-loading="form.loadingState">
      <el-form
        :model="form"
        ref="formRef"
        :rules="rules"
        inline-message
        size="small"
        label-position="right"
        label-width="142px"
      >
        <el-form-item class="custom-form-item" label="">
          <el-upload
            class="avatar-uploader"
            action="/"
            :accept="settings.accept"
            :before-upload="beforeUpload"
            :http-request="httpRequest"
            :show-file-list="false"
          >
            <div class="avatar-uploader-icon">
              <userAvatar :url="form.profileUrl"></userAvatar>
              <div class="camera-icon">
                <span class="icon iconfont iconxiangji"></span>
              </div>
            </div>
          </el-upload>
          <el-input size="small" :disabled="true" :model-value="form.username"></el-input>
          <el-input size="small" :disabled="true" :model-value="`ID: ${form.code ? form.code : form.id}`"></el-input>
        </el-form-item>
        <el-form-item prop="account" label="账号&nbsp;:">
          <el-input size="small" :disabled="true" v-model="form.loginName"></el-input>
        </el-form-item>
        <el-form-item prop="account" label="企业微信&nbsp;:">
          <el-input size="small" :disabled="true" v-model="form.userId"></el-input>
        </el-form-item>
        <div class="dashed-border"></div>
        <el-form-item label="手机号&nbsp;:" prop="mobile">
          <el-input size="small" disabled :maxlength="11" v-number v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="生日&nbsp;:">
          <el-date-picker
            v-model="form.birthday"
            format="YYYY-MM-DD"
            type="date"
            disabled
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="性别&nbsp;:">
          <span>{{ form.gender?.desc }}</span>
          <i
            v-if="form.gender?.id"
            class="iconfont grander-icon"
            :class="[form.gender?.id === '1' ? 'icon-nan man' : 'icon-nv woman']"
          ></i>
        </el-form-item>
        <el-form-item label="城市&nbsp;:">
          <el-cascader
            :options="cityData"
            disabled
            v-model="form.areaIds"
            separator=""
            popper-class="city-select-cascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="个性签名&nbsp;:">
          <el-input size="small" disabled v-model="form.sign"></el-input>
        </el-form-item>
        <el-form-item label="微信&nbsp;:">
          <el-input size="small" disabled v-model="form.weChat"></el-input>
        </el-form-item>
        <el-form-item label="邮箱&nbsp;:" prop="email">
          <el-input size="small" disabled v-model="form.email"></el-input>
        </el-form-item>
        <!-- <el-form-item label="" class="save-button">
          <el-button type="primary" :loading="loading" @click="saveClick">保存</el-button>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useFileUpload } from '@/hooks/upload'
import { useArea } from '@/hooks/area'
import { emailValidator, mobildValidator } from '@/common/validator'
import { getUserInfo, updateUserInfo } from '@/apis'
import { ElMessage } from 'element-plus'
import { timeFormat } from '@/common/utils/format'
import { useUser } from '@/hooks'
export default {
  name: 'info',
  components: {},
  props: {},
  setup() {
    const store = useStore()
    // 用户信息
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    const { user, setUser } = useUser()
    const { getProvinceAndCityData, getNameByCodes } = useArea()
    const cityData = computed(() => {
      return getProvinceAndCityData()
    })
    // 头像上传相关
    const { settings, beforeUpload, httpRequest } = useFileUpload(
      file => {
        console.log('上传成功', file)
        form.profileId = file.id
        form.profileUrl = file.id
      },
      { accept: '.png,.jpg', limit: 1, size: '10MB' }
    )
    const loading = ref(false)
    const formRef = ref(null)
    const form = reactive({
      profileUrl: '', // 头像
      profileId: '',
      id: '', // Id
      loginName: '', // 账号
      userId: '', // 企业微信Id
      mobile: '', // 手机号码
      birthday: '', // 生日
      gender: {}, // 性别
      email: '', // 邮箱
      weChat: '', // 微信
      areaIds: [],
      sign: '', // 个性签名
      loadingState: false
    })
    useInitUserInfo(userInfo.value.uuid, form)
    const rules = {
      mobile: [
        { required: true, message: '请输入手机号码', trigger: ['blur'] },
        { validator: mobildValidator, message: '手机号码格式有误', trigger: ['blur'] }
      ],
      email: {
        validator: emailValidator,
        message: '邮箱格式错误',
        trigger: ['blur']
      }
    }
    const handleRequeset = async params => {
      try {
        await updateUserInfo(params)
        ElMessage.success('保存成功')
        user.value.headUrl = form.profileId
        setUser(user.value)
        useInitUserInfo(userInfo.value.uuid, form)
      } catch (error) {
        console.log('handleRequeset', error)
      } finally {
        loading.value = false
      }
    }
    const saveClick = () => {
      formRef.value.validate(valid => {
        if (!valid) return false
        loading.value = true
        const params = {
          id: form.id,
          avatar: form.profileId,
          login_name: form.loginName,
          weChat: form.weChat,
          mobile: form.mobile,
          email: form.email,
          areaIds: form.areaIds,
          areaNames: getNameByCodes(form.areaIds),
          sign: form.sign,
          birthday: timeFormat(form.birthday, 'YYYY-MM-DD')
        }
        handleRequeset(params)
      })
    }

    return {
      loading,
      userInfo,
      form,
      formRef,
      rules,
      saveClick,
      settings,
      beforeUpload,
      httpRequest,
      cityData
    }
  }
}
export async function useInitUserInfo(id, form) {
  form.loadingState = true
  const accountInfo = ref({})
  const result = await getUserInfo(id)
  form.loadingState = false
  const { data } = result
  useInitUserInfo.value = data
  form.username = data.name
  form.id = data.id
  form.loginName = data.login_name
  form.userId = data.userId
  form.mobile = data.mobile || ''
  form.sign = data.sign || ''
  form.email = data.email || ''
  form.weChat = data.weChat || ''
  form.gender = data.gender || {}
  form.birthday = data.birthday || ''
  form.areaIds = data.areaIds || []
  form.profileUrl = data.avatar
  if (data.avatar) {
    form.profileId = data.avatar
  }
  return accountInfo
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
