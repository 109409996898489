import { provinceAndCityData, CodeToText } from 'element-china-area-data'
export const useArea = () => {
  const getProvinceAndCityData = () => {
    return provinceAndCityData
  }
  const getNameByCodes = codes => {
    if (typeof codes === 'string') return CodeToText[codes]
    return codes.map(code => CodeToText[code])
  }
  return {
    getProvinceAndCityData,
    getNameByCodes
  }
}
